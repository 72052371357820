import React from 'react';
import ProductRegistrationForm from './ProductRegistrationForm';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';

function RootPage({ themeData }) {
    return (
        <div>
            <ProductRegistrationForm themeData={ themeData } />
        </div>
    );
};

export default RootPage;
