import React from "react";
import "../styles/headers.scss";
import { useTranslation } from "react-i18next";
import MobileBanner from "../images/banner.png";

function Headers({ themeData }) {
  const { t } = useTranslation();
  return (
    <div className="header">
      <div className="header-topbar">
        <div>
          { themeData.websiteURL ? (
            <>
              <a
                href={ themeData.websiteURL }
                rel="noreferrer"
                className="return-to-main-website"
              >
                <i className="fa fa-arrow-left" style={ { color: themeData.cssTextColour } }></i>
                <span style={ { fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour } }> { t("header.return_to_main_website_button") }</span>
              </a>
              <a
                href={ themeData.websiteURL }
                rel="noreferrer"
                className="mobile-return-to-main-website"
              >
                <i className="fa fa-arrow-left"></i>
              </a>
            </>
          ) :
            <>
              <div className="return-to-main-website-blank">
              </div>
              <div className="mobile-return-to-main-website-blank">
              </div>
            </>
          }
        </div>
        <div>
          { themeData.logoURL ? (
            <img
              src={ themeData.logoURL }
              alt="brand log"
              className="main-logo"
            />
          ) : (
            <div className="main-logo"></div>
          ) }
        </div>
        <div>
          { themeData.secondaryLogoURL ? (
            <div className="amdea-logo-container">
              <img
                src={ themeData.secondaryLogoURL }
                alt="amdealogo"
                className="amdea-logo" />
            </div>
          ) : (
            <div className="amdea-logo-container">

            </div>
          ) }
        </div>
      </div>
      <img
        src="https://matchstick-men-group.s3.eu-west-1.amazonaws.com/media/resources/registrations/ProdRegBanner.png"
        alt="mobilebannerimage"
        className="mobile-banner"
      />
      <div className="header-banner">
        <img src="https://matchstick-men-group.s3.eu-west-1.amazonaws.com/media/resources/registrations/ProdRegBanner.png" />
        <div className="header-banner-text" style={ { fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour } }>
          <h4 style={ { paddingTop: '60px' } }>{ t("header.title") }</h4>
          <p>{ t("header.banner_line1") }</p>
          <p>{ t("header.banner_line2") }</p>
          <p style={ { paddingBottom: '30px' } }>{ t("header.banner_line3") }</p>
          <hr />
          <span>
            {(themeData.csPhone||themeData.csEmail) ? 
              (
                <>
                  {t("header.having_issues")}
                  {themeData.csPhone ? (<>{t("header.call_us_at")}{" "}{themeData.csPhone}</>):("")}
                  {(themeData.csPhone&&themeData.csEmail) ? (<>{" - "}</>):("")}
                  {themeData.csEmail ? (<>{t("header.email_us_at")}{" "}{themeData.csEmail}</>):("")}
                </>
              )
              : 
              ("")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Headers;
