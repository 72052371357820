import React from 'react';
import '../styles/footer.scss';
import { useTranslation } from 'react-i18next';
import pcg_logo from '../images/pcg_logo.png'

function Footer({ themeData }) {
    const { t } = useTranslation();
    return (
        <div className='footerContainer' style={ { fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour } }>
            <div className="footer">
                <div className="column">
                    <div className='column-flex thirdcolumn'>
                        <h6 style={ { fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour } }>{ t('footer.legal') }</h6>
                        <a
                            href={ `/terms-and-conditions` }
                            rel="noreferrer"
                        ><div style={ { fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour } }>{ t('footer.termsAndConditions') }</div></a>
                        <a
                            href={`https://www.productcaregroup.com/privacy-policy${themeData.locale==='en'?'': `?locale=${themeData.locale}`}`}
                            rel="noreferrer"
                        ><div style={ { fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour } }>{ t('footer.privacyPolicy') }</div></a>
                    </div>
                </div>

                <div className="column">
                    <div className='column-flex thirdcolumn' >
                        <h6 style={ { fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour } }>{ t('footer.customerServices') }</h6>
                        { themeData.contactUsFormUrl ? (
                            <a
                                href={ themeData.contactUsFormUrl }
                                rel="noreferrer"
                            ><div style={ { fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour } }>{ t('footer.contactUs') }</div></a>
                        ) : ("") }
                    </div>
                </div>

                <div className="column">
                    <div className='column-flex thirdcolumn info'>
                        <img src={ pcg_logo } alt="pcg_logo" />
                        <div><div style={ { fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour } }>{ t('footer.copyrightTxt') }</div></div>
                        { "" }
                        <div><div style={ { fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour } }>{ t('footer.websiteManagedTxt') }</div></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
