import './../styles/App.scss';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from '../components/ScrollToTop';
import RootPage from '../components/Home';
import Headers from '../components/Header';
import Footer from '../components/Footer';
import TermsAndConditions from '../components/TermsAndConditions';
import Page404 from '../components/404page';
import useSWR from 'swr';
import axios from "axios";
import i18next from 'i18next';
import pcg_logo from '../images/pcg_logo.png';

const fetcher = (args) => axios({ ...args, headers: { 'apikey': process.env.REACT_APP_PRODREG_API_KEY } }).then((response) => [ true, response.data ]).catch((error) => [ false, error.data ]);

function App() {
  // let pageContext = window.location.href.split('/')[ 4 ];
  let brandId = window.location.host.split('.')[0];
  if (brandId.toLowerCase()==='www') {
    brandId = window.location.host.split('.')[1];
  }
  // brandId = window.location.href.split('/')[ 3 ];
  const { data, isLoading } = useSWR({
    url: `${process.env.REACT_APP_PRODREG_API_ENDPOINT}/brand/${brandId}`,
    method: 'get'
  }, fetcher);
  let themeData = data;

  if (!isLoading) {
    themeData[ 0 ] ? i18next.changeLanguage(themeData[ 1 ].locale) : i18next.changeLanguage('en')
    if (themeData[ 0 ]) {
      document.title = `${themeData[1].name} Product Registration`;
      document.getElementsByTagName('meta')["description"].content = `${themeData[1].name} Product Registration`;
      let link = document.querySelector("link[rel~='icon']");
      link.href = themeData[ 1 ].faviconURL;
    }
    return (
      <BrowserRouter>
        <div className="App">
          { (themeData[ 0 ]) ?
            (
              <>
                <ScrollToTop />
                <Headers themeData={ themeData[ 1 ] } />
                <Routes>
                  <Route path="/terms-and-conditions" element={ <TermsAndConditions themeData={ themeData[ 1 ] } /> } />
                  <Route path="" element={ <RootPage themeData={ themeData[ 1 ] } /> } />
                  <Route path="/*" element={ <Page404 /> } />
                </Routes>
                <Footer themeData={ themeData[ 1 ] } />
              </>
            ) : (
              <Page404 />
            )
          }
        </div>
      </BrowserRouter>
    );
  } else {
    return (
      <div className="loading">
        <img src={ pcg_logo } alt="pcg_logo" />
      </div>
    )
  }

}

export default App;
