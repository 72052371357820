import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ThankYouPage from './ThankYouPage';
import "../styles/productRegistrationForm.scss";
import useDebounce from './useDebounce';
import axios from 'axios';
import { FileUploader } from "react-drag-drop-files";
import imageIcon from './../images/imageIcon.png';
import NoImage from './../images/NoImage.png';
import SerialNumberToolTip from './../images/serial_number.jpeg'
import AdditionalWarrantyIcon from './../images/additional_warranty_icon.png'
import { Tooltip } from 'react-tooltip'
import pcg_logo from '../images/pcg_logo.png'
import DatePicker from 'react-datepicker';
import 'react-datepicker/src/stylesheets/datepicker.scss';

// stripe integration part start
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
// stripe integration part end

function ProductRegistrationForm({ themeData }) {
  // translation object
  const { t } = useTranslation();

  const ref = useRef(null);
  // use state vars
  const [searchTermValue, setSearchTermValue] = useState('');
  const [isProductSearchComplete, setIsProductSearchComplete] = useState(false);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [isProductSelected, setIsProductSelected] = useState(false);
  const [findYourAddress, setFindYourAddress] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [availableProperties, setAvailableProperties] = useState([]);
  const [availableRetailers, setAvailableRetailers] = useState([]);
  const [ediAddressManually, setEdiAddressManually] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  // form status
  const [status, setStatus] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isApiSubmitted, setIsApiSubmitted] = useState(false);
  const [productRegistrationSubmitResponse, setProductRegistrationSubmitResponse] = useState({
    message: "",
    status: "",
    emailAddress: "",
    warranty: {
      id: 0,
      customer: 0,
      product: {
        id: 0,
        sku: "",
        name: "",
        brand: "",
        category: "",
        imageURL: null
      },
      extendedWarrantyPurchased: {
        amount: 0,
        length: 0,
        reference: ""
      }
    },
    extendedWarranty: {
      clientSecret: ""
    }
  });

  const [isProceedToPaymentClicked, setIsProceedToPaymentClicked] = useState(false);
  // stripe integration part start
  const [isAdditionalWarrantySelected, setIsAdditionalWarrantySelected] = useState(false);
  const [selectedAdditionalWarranty, setSelectedAdditionalWarranty] = useState({
    id: 0,
    length: 0,
    price: 0
  });

  const [isPaymentIntentReceived, setIsPaymentIntentReceived] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({
    id: "",
    status: ""
  });

  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const [isPatchCallComplete, setIsPatchCallComplete] = useState(false);
  const [isPatchApiCall, setIsPatchApiCall] = useState(false);
  // stripe integration part end

  const getDateInRequiredFormatAfterAddingMonths = (date, monthsToAdd) => {
    let copyOfCurrentDate = new Date(date);
    let newUpdatedDate = new Date(copyOfCurrentDate.setMonth(copyOfCurrentDate.getMonth() + monthsToAdd));
    return newUpdatedDate.toLocaleDateString("en-GB", { timeZone: "Europe/London" });
  };

  const [errorMessage, setErrorMessage] = useState({
    productSearchMessage: '',
    addressSearchMessage: '',
    warrantyCreateMessage: '',
    retailerMessage: '',
    paymentIntentError: ''
  });
  const [isSubmitFormAllowed, setIsSubmitFormAllowed] = useState({
    condition1Checked: false,
    condition2Checked: false,
    condition3Checked: false,
  })

  // product registration form details
  const [productRegistrationDetails, setProductRegistrationDetails] = useState({
    productDetails: {
      id: '',
      name: '',
      serialNumber: '',
      sku: '',
      retailer: '',
      dateOfPurchase: new Date(),
      extendedDateOfPurchase: '',
      proofOfPurchase: {}
    },
    personalDetails: {
      title: '',
      firstName: '',
      lastName: '',
      contactNumber: '',
      emailAddress: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      postcode: '',
      ip: ''
    }
  });

  // getting user ip address
  useEffect(() => {
    axios({
      method: 'get',
      url: "https://geolocation-db.com/json/"
    }).then((response) => {
      let copyProdRegForm = productRegistrationDetails
      copyProdRegForm.personalDetails.ip = response.data.IPv4
      setProductRegistrationDetails({ ...copyProdRegForm })
    })
  }, [themeData.locale, themeData.brandRef]);

  // getting active retailers
  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_PRODREG_API_ENDPOINT}/api/retailers?status=active`,
      headers: { 'apikey': process.env.REACT_APP_PRODREG_API_KEY, 'Accept': "Application/json" }
    }).then((response) => {
      if (response.data.length > 0) {
        let retailers = []
        if (response.data.length > 0) {
          response.data.map((retailer) => {
            retailers.push({
              label: retailer.name,
              value: retailer.id
            })
          })
        }
        setAvailableRetailers([...retailers])
      }
    }).catch((error) => {
      if (error.response.data.hasOwnProperty('detail')) {
        let newErrorMessage = errorMessage;
        newErrorMessage.retailerMessage = error.response.data.detail;
        setErrorMessage({ ...newErrorMessage });
        setAvailableRetailers([
          { label: 'No Retailers Found - other', value: 'other' },
        ])
      }
    })
  }, [themeData.locale, themeData.brandRef]);

  const debounceProductSearch = useDebounce(searchTermValue, 1000);

  // debouncing function
  useEffect(() => {
    if (debounceProductSearch) {
      setIsProductSearchComplete(false);
      setSearchedProducts([]);
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_PRODREG_API_ENDPOINT}/product/search?locale=${themeData.locale ? themeData.locale : 'en'}&s=${debounceProductSearch}&brandRef=${themeData.brandRef}`,
        headers: { 'apikey': process.env.REACT_APP_PRODREG_API_KEY }
      }).then((response) => {
        if (response.data?.count > 0) {
          setIsDropdownVisible(true);
          setSearchedProducts(response.data?.products);
          let newErrorMessage = errorMessage;
          newErrorMessage.productSearchMessage = "";
          setErrorMessage({ ...newErrorMessage });
        }
        if (response.data.hasOwnProperty('message')) {
          let newErrorMessage = errorMessage;
          newErrorMessage.productSearchMessage = response.data.message;
          setErrorMessage({ ...newErrorMessage });
        }
        setIsProductSearchComplete(true);
      }).catch((error) => {
        if (error.response.data.hasOwnProperty('detail')) {
          let newErrorMessage = errorMessage;
          newErrorMessage.productSearchMessage = error.response.data.detail;
          setErrorMessage({ ...newErrorMessage });
        }
        setIsProductSearchComplete(true);
      })
    }
  }, [debounceProductSearch]);


  const handelSelectedProduct = (productData) => {
    let newData = productRegistrationDetails;
    newData.productDetails.name = productData.name;
    newData.productDetails.sku = productData.sku;
    newData.productDetails.id = productData.id;
    setProductRegistrationDetails({ ...newData });
    setIsProductSelected(true);
    setSelectedProduct(productData);
    setIsDropdownVisible(false);
  }

  const debounceLocationSearch = useDebounce(findYourAddress, 1000);

  useEffect(() => {
    setAvailableProperties([]);
    if (debounceLocationSearch) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_GETADDRESS_ENDPOINT}/autocomplete/${debounceLocationSearch}?top=20&api-key=${process.env.REACT_APP_GETADDRESS_API_KEY}`,
      }).then((response) => {
        setAvailableProperties(response.data.suggestions);
      }).catch((error) => {
      })
    } else {
      setAvailableProperties([]);
    }
  }, [debounceLocationSearch]);

  const getFullAddress = (e) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_GETADDRESS_ENDPOINT}/get/${e.target.value}?api-key=${process.env.REACT_APP_GETADDRESS_API_KEY}`,
    }).then((response) => {
      if (response.data) {
        const personDetailData = { ...productRegistrationDetails.personalDetails };
        personDetailData.addressLine1 = response.data.line_1;
        personDetailData.addressLine2 = response.data.line_2;
        personDetailData.city = response.data.town_or_city;
        personDetailData.postcode = response.data.postcode;
        setProductRegistrationDetails({ ...productRegistrationDetails, personalDetails: personDetailData });
      }
    }).catch((error) => {
    })
  }

  const changeProductSelection = () => {
    setIsProductSelected(false);
    setSelectedProduct([]);
    setAvailableProperties([]);
    setSearchedProducts([]);
    setSearchTermValue("");
  }

  const fileTypes = ["PNG", "JPG", "JPEG"];

  const [popImage, setPopImage] = useState({
    name: '',
    fileUrl: '',
    base64String: ""
  });

  const handleUploadedImage = (file) => {
    let imageFile = file;
    let fileReader = new FileReader();
    fileReader.onload = () => {
      const srcData = fileReader.result;
      setPopImage({
        fileUrl: URL.createObjectURL(file),
        name: file.name,
        base64String: srcData
      });
      let copyProdRegForm = productRegistrationDetails
      copyProdRegForm.productDetails.proofOfPurchase = {
        'stream': srcData.split(";base64,")[1],
        'ext': file.name.split('.').at(-1)
      }
      setProductRegistrationDetails({ ...copyProdRegForm })
      setIsImageUploaded(true);
    };
    fileReader.readAsDataURL(imageFile);
  };


  const removeImage = () => {
    setPopImage({
      name: '',
      fileUrl: '',
      base64String: ""
    });
    let copyProdRegForm = productRegistrationDetails
    copyProdRegForm.productDetails.proofOfPurchase = {}
    setProductRegistrationDetails({ ...copyProdRegForm })
    setIsImageUploaded(false);
  }

  const [formValidation, setFormValidation] = useState({
    productId: false,
    productSerialNumber: false,
    dateOfPurchase: false,
    retailer: false,
    title: false,
    firstName: false,
    lastName: false,
    contactNumber: false,
    emailAddress: false,
    addressLine1: false,
    city: false,
    postcode: false,
  });
  const performFormChecks = () => {
    const validationFields = formValidation;

    // product details validation
    validationFields.productId = /^\s*$/.test(productRegistrationDetails.productDetails.id);
    validationFields.productSerialNumber = /^\s*$/.test(productRegistrationDetails.productDetails.serialNumber);
    
    // validationFields.dateOfPurchase = /^\s*$/.test(productRegistrationDetails.productDetails.dateOfPurchase);
    validationFields.dateOfPurchase = !(productRegistrationDetails.productDetails.dateOfPurchase instanceof Date);
    validationFields.retailer = /^\s*$/.test(productRegistrationDetails.productDetails.retailer);

    // customer data validation
    validationFields.title = /^\s*$/.test(productRegistrationDetails.personalDetails.title);
    validationFields.firstName = /^\s*$/.test(productRegistrationDetails.personalDetails.firstName);
    validationFields.lastName = /^\s*$/.test(productRegistrationDetails.personalDetails.lastName);
    validationFields.addressLine1 = /^\s*$/.test(productRegistrationDetails.personalDetails.addressLine1);
    validationFields.postcode = /^\s*$/.test(productRegistrationDetails.personalDetails.postcode);
    validationFields.city = /^\s*$/.test(productRegistrationDetails.personalDetails.city);
    validationFields.contactNumber = /^\s*$/.test(productRegistrationDetails.personalDetails.contactNumber);
    validationFields.emailAddress = !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(productRegistrationDetails.personalDetails.emailAddress);


    (validationFields.addressLine1 || validationFields.postcode || validationFields.city) ? setEdiAddressManually(true) : setEdiAddressManually(false)

    setFormValidation(validationFields);

    return (Object.values(validationFields).every((v) => !v)) ? true : false;
  }


  const prepareProductRegistrationApiDate = (isPatchCall) => {
    let productRegistraionFormDetails = {
      sendMail: isAdditionalWarrantySelected ? false : true,
      warranty: {
        dop: productRegistrationDetails.productDetails.dateOfPurchase.toISOString().split('T')[0],
        status: isAdditionalWarrantySelected ? 2 : 1,
        serialnumber: productRegistrationDetails.productDetails.serialNumber,
        retailer: productRegistrationDetails.productDetails.retailer,
        accept_terms: "1"
      },
      customer: {
        title: productRegistrationDetails.personalDetails.title,
        email: productRegistrationDetails.personalDetails.emailAddress,
        phone: productRegistrationDetails.personalDetails.contactNumber,
        mobile: productRegistrationDetails.personalDetails.contactNumber,
        address1: productRegistrationDetails.personalDetails.addressLine1,
        address2: productRegistrationDetails.personalDetails.addressLine2,
        city: productRegistrationDetails.personalDetails.city,
        firstname: productRegistrationDetails.personalDetails.firstName,
        lastname: productRegistrationDetails.personalDetails.lastName,
        postcode: productRegistrationDetails.personalDetails.postcode,
      },
      product: productRegistrationDetails.productDetails.id,
      user: {
        ip: productRegistrationDetails.personalDetails.ip,
        username: themeData.brandRef //"zanussiweb"
      }
    }

    if (isImageUploaded) {
      productRegistraionFormDetails.file = productRegistrationDetails.productDetails.proofOfPurchase
    }

    if (isAdditionalWarrantySelected) {
      productRegistraionFormDetails.extendedWarrantyRequest = { extendedWarrantyId: selectedAdditionalWarranty.id }
    }
    return productRegistraionFormDetails;
  }

  const handelProceedTopayment = (e) => {
    if (performFormChecks()) {
      setIsProceedToPaymentClicked(true);
      setIsFormSubmitted(true);
      setIsPaymentComplete(false);
      let apiDate = prepareProductRegistrationApiDate(false);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_PRODREG_API_ENDPOINT}/api/v2/warranties/save_warranty`,
        headers: { 'apikey': process.env.REACT_APP_PRODREG_API_KEY, 'Accept': "Application/json" },
        data: apiDate
      }).then((response) => {
        if (response.status === 200) {
          // saving the product registration response
          setIsPaymentIntentReceived(false);
          let apiResponse = response.data;
          let newProductRegistrationSubmitResponse = productRegistrationSubmitResponse;
          newProductRegistrationSubmitResponse.message = apiResponse.message;
          newProductRegistrationSubmitResponse.status = apiResponse.status;
          newProductRegistrationSubmitResponse.warranty = { ...newProductRegistrationSubmitResponse.warranty, ...apiResponse.warranty };
          newProductRegistrationSubmitResponse.emailAddress = apiDate.customer.email;
          newProductRegistrationSubmitResponse.extendedWarranty.clientSecret = apiResponse.extendedWarranty.client_secret;
          setProductRegistrationSubmitResponse({ ...newProductRegistrationSubmitResponse });
          if (productRegistrationSubmitResponse.extendedWarranty.clientSecret !== "" && productRegistrationSubmitResponse.extendedWarranty.clientSecret) {
            setIsFormSubmitted(true);
            setStatus(true);
            setIsApiSubmitted(true);
            setIsPaymentIntentReceived(true);
          } else {
            setIsFormSubmitted(true);
            setStatus(true);
            setIsApiSubmitted(true);
            setIsPaymentIntentReceived(false);
          }
        } else {
          setIsFormSubmitted(true);
          setStatus(false);
          setIsApiSubmitted(true);
        }
      }).catch((error) => {
        setIsFormSubmitted(true);
        setStatus(false);
        setIsApiSubmitted(true);
      })
    }
  }

  const handelProductRegistrationSubmit = (e) => {
    if (performFormChecks()) {
      setIsFormSubmitted(true);
      let apiDate = prepareProductRegistrationApiDate(false);
      // save warrenty call
      axios({
        method: "post",
        url: `${process.env.REACT_APP_PRODREG_API_ENDPOINT}/api/v2/warranties/save_warranty`,
        headers: { 'apikey': process.env.REACT_APP_PRODREG_API_KEY, 'Accept': "Application/json" },
        data: apiDate
      }).then((response) => {
        if (response.status === 200) {
          // saving the product registration response
          let apiResponse = response.data;
          let newProductRegistrationSubmitResponse = productRegistrationSubmitResponse;
          newProductRegistrationSubmitResponse.message = apiResponse.message;
          newProductRegistrationSubmitResponse.status = apiResponse.status;
          newProductRegistrationSubmitResponse.warranty = { ...newProductRegistrationSubmitResponse.warranty, ...apiResponse.warranty };
          newProductRegistrationSubmitResponse.emailAddress = apiDate.customer.email;
          setProductRegistrationSubmitResponse({ ...newProductRegistrationSubmitResponse });
          setIsFormSubmitted(true);
          setStatus(true);
          setIsApiSubmitted(true);
        } else {
          setIsFormSubmitted(true);
          setStatus(false);
          setIsApiSubmitted(true);
        }
      }).catch((error) => {
        setIsFormSubmitted(true);
        setStatus(false);
        setIsApiSubmitted(true);
      })
    }
  }

  const handlePayClick = (paymentResponse) => {
    console.log(`payment status ${paymentInfo.status === 'succeeded'}`)
    let newPaymentResponse = paymentResponse;
    let newPaymentInfo = paymentInfo;
    newPaymentInfo.id = newPaymentResponse.id
    newPaymentInfo.status = newPaymentResponse.status
    setPaymentInfo({ ...newPaymentInfo });
    setIsPaymentComplete(true);
    handelPatchCall();
  }

  const [patchCallApiStatus, setPatchCallApiStatus] = useState(false);

  const handelPatchCall = () => {
    setIsPatchCallComplete(false);
    console.log(`payment status ${paymentInfo.status === 'succeeded'}`)
    let pathCallRequestData = {
      "id": productRegistrationSubmitResponse.warranty.id,
      "payment": {
        "type": "stripe",
        "reference": paymentInfo.id,
        "status": paymentInfo.status
      },
      "note": paymentInfo.status === 'succeeded' ? "Extension bought from website" : "Extension payment failed",
      "user": {
        "ip": productRegistrationDetails.personalDetails.ip,
        "username": themeData.brandRef
      }
    }

    if (paymentInfo.status === 'succeeded') {
      pathCallRequestData['extendedWarrantyPurchased'] = {
        "extendedWarrantyId": selectedAdditionalWarranty.id
      }
    }
    // save warrenty call
    axios({
      method: "post",
      url: paymentInfo.status === 'succeeded' ? `${process.env.REACT_APP_PRODREG_API_ENDPOINT}/api/v2/warranties/save_ext_warranty` : `${process.env.REACT_APP_PRODREG_API_ENDPOINT}/api/v2/warranties/ext_warranty_failed`,
      headers: { 'apikey': process.env.REACT_APP_PRODREG_API_KEY, 'Accept': "Application/json" },
      data: pathCallRequestData
    }).then((response) => {
      if (response.status === 200) {
        // saving the product registration response
        let apiResponse = response.data;
        let newProductRegistrationSubmitResponse = productRegistrationSubmitResponse;
        newProductRegistrationSubmitResponse.message = apiResponse.message;
        newProductRegistrationSubmitResponse.status = apiResponse.status;
        newProductRegistrationSubmitResponse.warranty = { ...productRegistrationSubmitResponse.warranty, ...apiResponse.warranty };

        // setting payment reference id for failed payments
        if (paymentInfo.status !== 'succeeded') {
          newProductRegistrationSubmitResponse.warranty.extendedWarrantyPurchased.reference = paymentInfo.id
        }

        setProductRegistrationSubmitResponse({ ...newProductRegistrationSubmitResponse });
        setIsPatchCallComplete(true);
        setPatchCallApiStatus(true);
      } else {
        setIsPatchCallComplete(true);
        setPatchCallApiStatus(false);
      }
    }).catch((error) => {
      setIsPatchCallComplete(true);
      setPatchCallApiStatus(false);
    })
  }


  return (
    <div>
      {isDropdownVisible && <div className='outer-layout' onClickCapture={() => setIsDropdownVisible(false)}></div>}
      {!isFormSubmitted ? (
        <div className='productRegistrationContainer' style={{ fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour }}>
          {/* form fields are being created here */}
          <div className='productRegistrationForm'>
            {/* start of product details */}
            <div className='productDetailsForm'>
              <h3 className='title' style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.titles.product_details')}</h3>
              <p className='title'>{t('product_registration_form.titles.fields_required')}
                <span className='required'>*</span>
                <span
                  style={{ color: "#ff0000", fontSize: "15px", marginLeft: "10px" }}
                >
                  {" "}
                </span>
              </p>
              {isProductSelected &&
                <div className='selectedItemsContainer'>
                  <div className='FormField'>
                    <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.form_labels_and_other_text.select_your_product.registering')}</label>
                  </div>
                  <div className='selectedItems'>
                    <ul className="SearchDropdown">
                      <li className='liSearchDropdownItem'>
                        <div className='searchedProductItem'>

                          <div className='productData'>
                            <p className='productName'>{selectedProduct.name !== '' ? selectedProduct.name : ''}</p>
                            <div className='productDescriptionContainer'>
                              <div className='productImage'>
                                <img src={selectedProduct.imageURL ? selectedProduct.imageURL : NoImage} alt="ImageNotAvailable" />
                              </div>
                              <p className="productDescription"><b>{t('product_registration_form.form_labels_and_other_text.select_your_product.model_number')}:</b>  {selectedProduct.sku}</p>
                              <p className="productDescription"><b>{t('product_registration_form.form_labels_and_other_text.select_your_product.product_category')}:</b>  {selectedProduct.category}</p>
                            </div>
                          </div>
                          <div>
                            <button onClickCapture={changeProductSelection} style={{ fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour }}>
                              {t('product_registration_form.form_labels_and_other_text.select_your_product.change_selection')}
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className='registerBenefit' style={{ textAlign: "left" }}>
                      <div className='mainTitle' style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>
                        {t('product_registration_form.form_labels_and_other_text.select_your_product.selectedInfoLine1')}
                      </div>
                      <div>
                        <div className='lifeofproduct'>
                          {t('product_registration_form.form_labels_and_other_text.select_your_product.selectedInfoLine5')} {selectedProduct.length / 12} {t('product_registration_form.form_labels_and_other_text.select_your_product.year')}
                        </div>
                        <div className='lifecontent'>
                          {t('product_registration_form.form_labels_and_other_text.select_your_product.selectedInfoLine6')}
                        </div>
                      </div>
                      <div>
                        <div className='lifeofproduct'>
                          {t('product_registration_form.form_labels_and_other_text.select_your_product.selectedInfoLine2')}
                        </div>
                        <div className='lifecontent'>
                          {t('product_registration_form.form_labels_and_other_text.select_your_product.selectedInfoLine3')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {/* Select your Product  */}
              {!isProductSelected && (<div className='FormField' style={{ position: "relative" }}>
                <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{t('product_registration_form.form_labels_and_other_text.select_your_product.label')}
                  <span className='required'>*</span>
                  {formValidation.productId && <span style={{ color: "#ff0000", fontSize: "15px", marginLeft: "10px" }}
                  >
                    required
                  </span>}
                  <span style={{ color: '#c21a1a', fontFamily: themeData.cssTextFontFamily, fontSize: "12px" }}> {errorMessage?.productSearchMessage ? errorMessage?.productSearchMessage : ""}</span>
                </label>
                <input
                  type="text"
                  required
                  ref={ref}
                  placeholder={t('product_registration_form.form_labels_and_other_text.select_your_product.placeholder')}
                  value={searchTermValue}
                  onChange={(e) => setSearchTermValue(e.target.value)}
                />
                <div className='inputSearchLoading' style={{ display: (searchTermValue.length > 0 && !searchedProducts.length > 0 && !isProductSearchComplete) ? 'block' : 'none', right: "-20px", marginTop: "50px", position: "absolute" }}>
                  <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                    <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50" color={themeData.cssPrimaryColour} fill={themeData.cssPrimaryColour}>
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        dur="2s"
                        from="0 50 50"
                        to="360 50 50"
                        repeatCount="indefinite" />
                    </path>
                  </svg>
                </div>
                {isDropdownVisible && searchTermValue.length >= 4 && searchedProducts && ((searchedProducts.length > 0 && isProductSearchComplete) ? (
                  <div className='searchedProductsDropDown'>
                    <ul className="SearchDropdown" id="closeDropdown">
                      {
                        searchedProducts.map(function (item, idx) {
                          return (
                            <li className='liSearchDropdownItem' key={item.id} onClick={() => handelSelectedProduct(item)}>
                              <div className='searchedProductItem'>
                                <div className='productData'>
                                  <p className='productName'>{item.name !== '' ? item.name : ''}</p>
                                  <div className='productDescriptionContainer'>
                                    <div className='productImage'>
                                      <img src={item.imageURL ? item.imageURL : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDpYgKX6Na9EAfhKgjLD4iyPugeNE0wggdkw&usqp=CAU"} alt="ImageNotAvailable" />
                                    </div>
                                    <p className="productDescription"><b>{t('product_registration_form.form_labels_and_other_text.select_your_product.model_number')}:</b>  {item.sku}</p>
                                    <p className="productDescription"><b>{t('product_registration_form.form_labels_and_other_text.select_your_product.product_category')}:</b>  {item.category}</p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                ) : (
                  (isProductSearchComplete ? (
                    <div className='searchedProductsDropDown'>
                      <ul className="SearchDropdown" id="closeDropdown">
                        <li className='liSearchDropdownItem' style={{ padding: "15px" }}>
                          <span>{t("product_registration_form.titles.no_product_found")}</span>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className='searchedProductsDropDown'>
                      <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve" style={{ width: "30px", height: "30px" }}>
                        <path fill={themeData.cssPrimaryColour} d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50" color={themeData.cssPrimaryColour}>
                          <animateTransform
                            attributeName="transform"
                            attributeType="XML"
                            type="rotate"
                            dur="2s"
                            from="0 50 50"
                            to="360 50 50"
                            repeatCount="indefinite" />
                        </path>
                      </svg>
                    </div>
                  ))
                ))
                }
              </div>)}
              {/* Enter product serial number  */}
              <div className='FormField'>
                <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>
                  {t('product_registration_form.form_labels_and_other_text.enter_your_product_serial_number.label')}
                  <span className='required'>*</span>
                  {formValidation.productSerialNumber && <span style={{ color: "#ff0000", fontSize: "15px", marginLeft: "10px" }}
                  >
                    {"required"}
                  </span>}
                  <p
                    style={{ color: themeData.cssTextColour, fontFamily: themeData.cssTextFontFamily, marginTop: "-28px", marginLeft: "70%", textAlign: "right", marginBottom: "0px" }}
                    data-tooltip-id="whatisthis"
                  >{t('product_registration_form.form_labels_and_other_text.enter_your_product_serial_number.what_is_this')}</p>
                  <Tooltip style={{ width: "300px", height: "300px", borderRadius: "6px", zIndex: "1" }} id="whatisthis" content={(
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <img src={SerialNumberToolTip} width="auto" height="230px" />
                      <p style={{ textAlign: "center" }}>{t('product_registration_form.form_labels_and_other_text.enter_your_product_serial_number.whereisit')}</p>
                    </div>
                  )} />
                </label>
                <input
                  type="text"
                  required
                  placeholder={t('product_registration_form.form_labels_and_other_text.enter_your_product_serial_number.placeholder')}
                  value={productRegistrationDetails.productDetails.serialNumber}
                  onChange={(e) => {
                    let formData = productRegistrationDetails;
                    formData.productDetails.serialNumber = e.target.value
                    setProductRegistrationDetails({ ...formData })
                    let newFormValidation = formValidation
                    newFormValidation.productSerialNumber = /^\s*$/.test(productRegistrationDetails.productDetails.serialNumber);
                    setFormValidation({ ...newFormValidation })
                  }}
                />
              </div>
              {/* Where and when did you buy your product?  */}
              <div className='FormField'>
                <div className='groupDateAndRetailer'>
                  <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.form_labels_and_other_text.where_and_when_did_you_buy_your_product.label')}<span className='required'></span></label>
                  <p>{t('product_registration_form.form_labels_and_other_text.where_and_when_did_you_buy_your_product.description')}</p>
                </div>
                <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.form_labels_and_other_text.where_and_when_did_you_buy_your_product.labelDate')}
                  <span className='required'>*</span>
                  {formValidation.dateOfPurchase && <span style={{ color: "#ff0000", fontSize: "15px", marginLeft: "10px" }}>
                    {"required"}
                  </span>}
                </label>
                <DatePicker
                  showIcon
                  required={true}
                  selected={productRegistrationDetails.productDetails.dateOfPurchase}
                  dateFormat="MM/dd/yyyy"
                  maxDate={new Date()}
                  minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
                  onChange={(e) => {
                    let formData = productRegistrationDetails;
                    formData.productDetails.dateOfPurchase = e
                    setProductRegistrationDetails({ ...formData })
                    let newFormValidation = formValidation
                    newFormValidation.dateOfPurchase = !(productRegistrationDetails.productDetails.dateOfPurchase instanceof Date);
                    setFormValidation({ ...newFormValidation })
                  }}
                />
              </div>
              {/* Select retailer you purchased your product from  */}
              <div className='FormField'>
                <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.form_labels_and_other_text.select_retailer_you_purchased_your_product_from.label')}
                  <span className='required'>*</span>
                  {formValidation.retailer && <span style={{ color: "#ff0000", fontSize: "15px", marginLeft: "10px" }}
                  >
                    {"required"}
                  </span>}
                </label>
                <select
                  name="retailer"
                  id="retailer"
                  onChange={(e) => {
                    let formData = productRegistrationDetails;
                    formData.productDetails.retailer = e.target.value
                    setProductRegistrationDetails({ ...formData })
                    let newFormValidation = formValidation
                    newFormValidation.retailer = /^\s*$/.test(productRegistrationDetails.productDetails.retailer);
                    setFormValidation({ ...newFormValidation })
                  }}
                >
                  <option value="" key="" >{t('product_registration_form.form_labels_and_other_text.select_retailer_you_purchased_your_product_from.placeholder')}</option>
                  {availableRetailers.map((retailer) => <option value={retailer.value} key={retailer.value}>{retailer.label}</option>)}
                </select>
              </div>
              {/* Upload a picture/scan of your receipt(Proof of purchase) below */}
              <div className='FormField'>
                <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.form_labels_and_other_text.upload_a_picture_scan_of_your_receipt_below.label')}</label>
                <p>{t('product_registration_form.form_labels_and_other_text.upload_a_picture_scan_of_your_receipt_below.description')}</p>
                <div className='imageplaceholder'>
                  {!isImageUploaded ? (
                    <FileUploader
                      handleChange={handleUploadedImage}
                      name="PoPImageUpload"
                      types={fileTypes}
                      classes="FileUploaderButton"
                      children={(
                        <div className="ImageDragAndDropContainer">
                          <div className="imageIcon">
                            <img src={imageIcon} />
                          </div>
                          <div className="textPlaceHolder">
                            <p className='box-text' style={{ textAlign: 'center' }}>
                              {t('product_registration_form.form_labels_and_other_text.upload_a_picture_scan_of_your_receipt_below.image_placeholder1')}
                            </p>
                            <p className='box-text' style={{ textAlign: 'center' }}>
                              {t('product_registration_form.form_labels_and_other_text.upload_a_picture_scan_of_your_receipt_below.or')}
                            </p>
                            <p className='box-text' style={{ textAlign: 'center' }}>
                              {t('product_registration_form.form_labels_and_other_text.upload_a_picture_scan_of_your_receipt_below.image_placeholder2')}
                            </p>
                          </div>
                        </div>
                      )}
                    />) : (
                    <div className='previewImage'>
                      <img src={popImage.fileUrl} />
                      <i className="fa fa-close fa-1x" onClick={removeImage} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* end of product details */}
            {/* start of personal details */}
            <div className='personalDetailsForm'>
              <h3 className='title' style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.titles.personal_details')}</h3>
              <p className='title'>{t('product_registration_form.titles.fields_required')} <span className='required'>*</span></p>
              {/* Title  */}
              <div className='FormField'>
                <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>
                  {t('product_registration_form.form_labels_and_other_text.title.label')}
                  <span className='required'>*</span>
                  {formValidation.title && <span style={{ color: "#ff0000", fontSize: "15px", marginLeft: "10px" }}>
                    {"required"}
                  </span>}
                </label>
                <select
                  name="title"
                  id="title"
                  onChange={(e) => {
                    let formData = productRegistrationDetails;
                    formData.personalDetails.title = e.target.value
                    setProductRegistrationDetails({ ...formData })
                    let newFormValidation = formValidation
                    newFormValidation.title = /^\s*$/.test(productRegistrationDetails.personalDetails.title);
                    setFormValidation({ ...newFormValidation })
                  }}
                >
                  <option value="" >{t('product_registration_form.form_labels_and_other_text.title.placeholder')}</option>
                  <option value="Mr" >{t('product_registration_form.form_labels_and_other_text.title.mr')}</option>
                  <option value="Ms" >{t('product_registration_form.form_labels_and_other_text.title.ms')}</option>
                  <option value="Mrs" >{t('product_registration_form.form_labels_and_other_text.title.mrs')}</option>
                  <option value="Miss" >{t('product_registration_form.form_labels_and_other_text.title.miss')}</option>
                  <option value="Mx" >{t('product_registration_form.form_labels_and_other_text.title.mx')}</option>
                  <option value="Dr" >{t('product_registration_form.form_labels_and_other_text.title.dr')}</option>
                  <option value="Prof" >{t('product_registration_form.form_labels_and_other_text.title.prof')}</option>
                  <option value="other" >{t('product_registration_form.form_labels_and_other_text.title.nottosay')}</option>
                </select>
              </div>
              {/* First name  */}
              <div className='FormField'>
                <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.form_labels_and_other_text.first_name.label')}
                  <span className='required'>*</span>
                  {formValidation.firstName && <span style={{ color: "#ff0000", fontSize: "15px", marginLeft: "10px" }}>
                    {"required"}
                  </span>}
                </label>
                <input
                  type="text"
                  required
                  placeholder={t('product_registration_form.form_labels_and_other_text.first_name.placeholder')}
                  value={productRegistrationDetails.personalDetails.firstName}
                  onChange={(e) => {
                    let formData = productRegistrationDetails;
                    formData.personalDetails.firstName = e.target.value
                    setProductRegistrationDetails({ ...formData })
                    let newFormValidation = formValidation
                    newFormValidation.firstName = /^\s*$/.test(productRegistrationDetails.personalDetails.firstName);
                    setFormValidation({ ...newFormValidation })
                  }}
                />
              </div>
              {/* Last name  */}
              <div className='FormField'>
                <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.form_labels_and_other_text.last_name.label')}
                  <span className='required'>*</span>
                  {formValidation.lastName && <span style={{ color: "#ff0000", fontSize: "15px", marginLeft: "10px" }}>
                    {"required"}
                  </span>}
                </label>
                <input
                  type="text"
                  required
                  placeholder={t('product_registration_form.form_labels_and_other_text.last_name.placeholder')}
                  value={productRegistrationDetails.personalDetails.lastName}
                  onChange={(e) => {
                    let formData = productRegistrationDetails;
                    formData.personalDetails.lastName = e.target.value
                    setProductRegistrationDetails({ ...formData })
                    let newFormValidation = formValidation
                    newFormValidation.lastName = /^\s*$/.test(productRegistrationDetails.personalDetails.lastName);
                    setFormValidation({ ...newFormValidation })
                  }}
                />
              </div>
              {/* Contact number  */}
              <div className='FormField'>
                <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.form_labels_and_other_text.contact_number.label')}
                  <span className='required'>*</span>
                  {formValidation.contactNumber && <span style={{ color: "#ff0000", fontSize: "15px", marginLeft: "10px" }}>
                    {"required"}
                  </span>}
                </label>
                <input
                  type="tel"
                  required
                  placeholder={t('product_registration_form.form_labels_and_other_text.contact_number.placeholder')}
                  value={productRegistrationDetails.personalDetails.contactNumber}
                  onChange={(e) => {
                    let formData = productRegistrationDetails;
                    formData.personalDetails.contactNumber = e.target.value
                    setProductRegistrationDetails({ ...formData })
                    let newFormValidation = formValidation
                    newFormValidation.contactNumber = /^\s*$/.test(productRegistrationDetails.personalDetails.contactNumber);
                    setFormValidation({ ...newFormValidation })
                  }}
                />
              </div>
              {/* Email address  */}
              <div className='FormField'>
                <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.form_labels_and_other_text.email_address.label')}
                  <span className='required'>*</span>
                  {formValidation.emailAddress && <span style={{ color: "#ff0000", fontSize: "15px", marginLeft: "10px" }}>
                    {"required"}
                  </span>}
                </label>
                <input
                  type="email"
                  required
                  placeholder={t('product_registration_form.form_labels_and_other_text.email_address.placeholder')}
                  value={productRegistrationDetails.personalDetails.emailAddress}
                  onChange={(e) => {
                    let formData = productRegistrationDetails;
                    formData.personalDetails.emailAddress = e.target.value
                    setProductRegistrationDetails({ ...formData })
                    let newFormValidation = formValidation
                    newFormValidation.emailAddress = !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(productRegistrationDetails.personalDetails.emailAddress);
                    setFormValidation({ ...newFormValidation })
                  }}
                />
              </div>
              <div className='FormField'>
                <div className='findyouraddress'>
                  {/* Find your address  */}
                  <div className='FormField'>
                    <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.form_labels_and_other_text.find_your_address.label')}
                      <span className='required'>*</span>
                      {(formValidation.addressLine1 || formValidation.city || formValidation.postcode) && <span style={{ color: "#ff0000", fontSize: "15px", marginLeft: "10px" }}>
                        {"required"}
                      </span>}
                    </label>
                    <div className='addressThingGroup'>
                      <input
                        type="text"
                        required
                        placeholder={t('product_registration_form.form_labels_and_other_text.find_your_address.placeholder')}
                        value={findYourAddress}
                        onChange={(e) => {
                          setFindYourAddress(e.target.value)
                        }}
                      />
                      <div className='inputSearchLoading' style={{ display: (findYourAddress.length > 0 && !availableProperties.length > 0) ? 'block' : 'none' }}>
                        <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                          <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50" color={themeData.cssPrimaryColour} fill={themeData.cssPrimaryColour}>
                            <animateTransform
                              attributeName="transform"
                              attributeType="XML"
                              type="rotate"
                              dur="2s"
                              from="0 50 50"
                              to="360 50 50"
                              repeatCount="indefinite" />
                          </path>
                        </svg>
                      </div>
                    </div>
                  </div>
                  {/* Select Property  */}
                  <div className='FormField'>
                    <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.form_labels_and_other_text.select_property.label')}
                      <span className='required'>*</span>
                      {(formValidation.addressLine1 || formValidation.city || formValidation.postcode) && <span style={{ color: "#ff0000", fontSize: "15px", marginLeft: "10px" }}>
                        {"required"}
                      </span>}
                    </label>
                    <select onChangeCapture={getFullAddress} name="select_property" id="select_property">
                      <option value="" key={-1}>{t('product_registration_form.form_labels_and_other_text.select_property.placeholder')}</option>
                      {availableProperties.map((property, index) => <option key={index} value={property.id}>{property.address}</option>)}
                    </select>
                  </div>
                </div>
              </div>
              <div className='FormField'>
                <button className='enterAddressManuallyButton' style={{ backgroundColor: themeData.cssPrimaryColour, color: themeData.cssSecondaryColour }}
                  onClick={(e) => {
                    setEdiAddressManually(!ediAddressManually);
                  }}>
                  {t('product_registration_form.form_labels_and_other_text.enter_address_manually_button')}
                </button>
              </div>
              {
                ediAddressManually ? (
                  <div className='editAddressManually'>
                    {/* Address line 1  */}
                    <div className='FormField'>
                      <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.form_labels_and_other_text.address_line_1.label')}
                        <span className='required'>*</span>
                        {(formValidation.addressLine1) && <span style={{ color: "#ff0000", fontSize: "15px", marginLeft: "10px" }}>
                          {"required"}
                        </span>}
                      </label>
                      <input
                        type="text"
                        required
                        placeholder={t('product_registration_form.form_labels_and_other_text.address_line_1.placeholder')}
                        value={productRegistrationDetails.personalDetails.addressLine1}
                        onChange={(e) => {
                          let formData = productRegistrationDetails;
                          formData.personalDetails.addressLine1 = e.target.value
                          setProductRegistrationDetails({ ...formData })
                          let newFormValidation = formValidation
                          newFormValidation.addressLine1 = /^\s*$/.test(productRegistrationDetails.personalDetails.addressLine1);
                          setFormValidation({ ...newFormValidation })
                        }}
                      />
                    </div>
                    {/* Address line 2  */}
                    <div className='FormField'>
                      <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.form_labels_and_other_text.address_line_2.label')}
                      </label>
                      <input
                        type="text"
                        required
                        placeholder={t('product_registration_form.form_labels_and_other_text.address_line_2.placeholder')}
                        value={productRegistrationDetails.personalDetails.addressLine2}
                        onChange={(e) => {
                          let formData = productRegistrationDetails;
                          formData.personalDetails.addressLine2 = e.target.value
                          setProductRegistrationDetails({ ...formData })
                        }}
                      />
                    </div>
                    {/* City  */}
                    <div className='FormField'>
                      <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.form_labels_and_other_text.city.label')}
                        <span className='required'>*</span>
                        {(formValidation.city) && <span style={{ color: "#ff0000", fontSize: "15px", marginLeft: "10px" }}>
                          {"required"}
                        </span>}
                      </label>
                      <input
                        type="text"
                        required
                        placeholder={t('product_registration_form.form_labels_and_other_text.city.placeholder')}
                        value={productRegistrationDetails.personalDetails.city}
                        onChange={(e) => {
                          let formData = productRegistrationDetails;
                          formData.personalDetails.city = e.target.value
                          setProductRegistrationDetails({ ...formData })
                          let newFormValidation = formValidation
                          newFormValidation.city = /^\s*$/.test(productRegistrationDetails.personalDetails.city);
                          setFormValidation({ ...newFormValidation })
                        }}
                      />
                    </div>
                    {/* Postcode  */}
                    <div className='FormField'>
                      <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.form_labels_and_other_text.postcode.label')}
                        <span className='required'>*</span>
                        {(formValidation.postcode) && <span style={{ color: "#ff0000", fontSize: "15px", marginLeft: "10px" }}>
                          {"required"}
                        </span>}
                      </label>
                      <input
                        type="text"
                        required
                        placeholder={t('product_registration_form.form_labels_and_other_text.postcode.placeholder')}
                        value={productRegistrationDetails.personalDetails.postcode}
                        onChange={(e) => {
                          let formData = productRegistrationDetails;
                          formData.personalDetails.postcode = e.target.value
                          setProductRegistrationDetails({ ...formData })
                          let newFormValidation = formValidation
                          newFormValidation.postcode = /^\s*$/.test(productRegistrationDetails.personalDetails.postcode);
                          setFormValidation({ ...newFormValidation })
                        }}
                      />
                    </div>
                  </div>
                ) : ""
              }
              <p>{t('product_registration_form.form_labels_and_other_text.personal_details_collection_text')}</p>
            </div>
          </div>
          <div className='productDetailsFormSeparator' />
          {(selectedProduct.hasOwnProperty('extensions') && selectedProduct.extensions.length > 0) ? (
            <div>
              {/* additional warrenty section */}
              <div className='additionalWarrentySection' style={{ fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour }}>
                <h3 style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('additional_warranty_section.additional_warranty_options')}</h3>
                <div className='faq'>
                  <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('additional_warranty_section.why_purchase_an_additional_warrenty')}</label>
                  <p>{t('additional_warranty_section.why_purchase_an_additional_warrenty_text1')}</p>
                  <p>{t('additional_warranty_section.why_purchase_an_additional_warrenty_text2')}</p>
                </div>
                <div className='faq'>
                  <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('additional_warranty_section.this_warranty_covers')}</label>
                  <p>{t('additional_warranty_section.this_warranty_covers_text1')}</p>
                  <p>{t('additional_warranty_section.this_warranty_covers_text2')}</p>
                </div>
                <p>{t('additional_warranty_section.before_fields_text')}</p>
                <div>
                  {selectedProduct.extensions.map((extension, idx) => (
                    <div className='additionalWarrantyOption' key={idx} style={{ backgroundColor: themeData.cssSecondaryColour }}>
                      <button
                        onClick={() => {
                          if (selectedAdditionalWarranty.id === extension.id) {
                            setIsAdditionalWarrantySelected(false)
                            let newSelectedAdditionalWarranty = selectedAdditionalWarranty
                            newSelectedAdditionalWarranty.id = 0
                            newSelectedAdditionalWarranty.length = 0
                            newSelectedAdditionalWarranty.price = 0
                            setSelectedAdditionalWarranty({ ...newSelectedAdditionalWarranty });
                          } else {
                            setIsAdditionalWarrantySelected(true)
                            let newSelectedAdditionalWarranty = selectedAdditionalWarranty
                            newSelectedAdditionalWarranty.id = extension.id
                            newSelectedAdditionalWarranty.length = extension.length
                            newSelectedAdditionalWarranty.price = parseFloat(extension.price)
                            setSelectedAdditionalWarranty({ ...newSelectedAdditionalWarranty });
                          }
                        }}
                      >
                        <div className='extendedWarrantyOptionDesktop' style={{ fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour }}>
                          {selectedAdditionalWarranty.id == extension.id ? (
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.2426 16.3137L6 12.071L7.41421 10.6568L10.2426 13.4853L15.8995 7.8284L17.3137 9.24262L10.2426 16.3137Z"
                                fill="currentColor"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
                                fill="currentColor"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                fill="currentColor"
                              />
                            </svg>
                          )}
                          <img src={AdditionalWarrantyIcon} />
                          <div className='additionalWarrantyOptionInfo'>
                            <label>{extension.length / 12} {t('additional_warranty_section.year_warranty')}</label>
                            <p>{t('additional_warranty_section.select_text')}</p>
                            <p style={{ fontWeight: "600" }}>{t('additional_warranty_section.expiry_date_text')} {getDateInRequiredFormatAfterAddingMonths(productRegistrationDetails.productDetails.dateOfPurchase, (extension.length + selectedProduct.length))}</p>
                          </div>
                          <div className='additionalWarrantyOptionPrice'>
                            £{extension.price}
                          </div>
                        </div>
                        <div className='extendedWarrantyOptionMobile' style={{ fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour }}>
                          <div>
                            {selectedAdditionalWarranty.id == extension.id ? (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.2426 16.3137L6 12.071L7.41421 10.6568L10.2426 13.4853L15.8995 7.8284L17.3137 9.24262L10.2426 16.3137Z"
                                  fill="currentColor"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
                                  fill="currentColor"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                  fill="currentColor"
                                />
                              </svg>
                            )}
                            <img src={AdditionalWarrantyIcon} />
                            <div className='additionalWarrantyOptionPrice' style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>
                              £{extension.price}
                            </div>
                          </div>
                          <div className='additionalWarrantyOptionInfo'>
                            <label style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{extension.length / 12} {t('additional_warranty_section.year_warranty')}</label>
                            <p>{t('additional_warranty_section.select_text')}</p>
                            <p style={{ fontWeight: "600", fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('additional_warranty_section.expiry_date_text')} {getDateInRequiredFormatAfterAddingMonths(productRegistrationDetails.productDetails.dateOfPurchase, (extension.length + selectedProduct.length))}</p>
                          </div>
                        </div>
                      </button>
                    </div>
                  )
                  )}
                </div>
              </div>
              <div className='productDetailsFormSeparator' />
            </div>
          ) : (
            <></>
          )}
          {/* terms and conditions section */}
          <div className='termsAndConditionsChecksContainer'>
            <h3 style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('product_registration_form.form_labels_and_other_text.terms_and_conditions.label')}</h3>
            <p>{t('product_registration_form.form_labels_and_other_text.terms_and_conditions.description')}</p>
            <div className='termsAndConditionsChecks'>
              <input type="checkbox" key="t&c1" id="t&c1" onClick={(e) => {
                let copyIsSubmitFormAllowed = isSubmitFormAllowed;
                copyIsSubmitFormAllowed.condition1Checked = !copyIsSubmitFormAllowed.condition1Checked
                setIsSubmitFormAllowed({ ...copyIsSubmitFormAllowed })
              }} />
              <p>{t('product_registration_form.form_labels_and_other_text.terms_and_conditions.condition1')} <span className='required'>*</span></p>
            </div>
            <div className='termsAndConditionsChecks'>
              <input type="checkbox" key="t&c2" id="t&c2" onClick={(e) => {
                let copyIsSubmitFormAllowed = isSubmitFormAllowed;
                copyIsSubmitFormAllowed.condition2Checked = !copyIsSubmitFormAllowed.condition2Checked
                setIsSubmitFormAllowed({ ...copyIsSubmitFormAllowed })
              }} />
              <p>{t('product_registration_form.form_labels_and_other_text.terms_and_conditions.condition2')} <a href={`https://www.productcaregroup.com/privacy-policy${themeData.locale === 'en' ? '' : `?locale=${themeData.locale}`}`}>{t('product_registration_form.form_labels_and_other_text.terms_and_conditions.privacy_policy')}</a> <span className='required'>*</span></p>
            </div>
            <div className='termsAndConditionsChecks'>
              <input type="checkbox" key="t&c3" id="t&c3" onClick={(e) => {
                let copyIsSubmitFormAllowed = isSubmitFormAllowed;
                copyIsSubmitFormAllowed.condition3Checked = !copyIsSubmitFormAllowed.condition3Checked
                setIsSubmitFormAllowed({ ...copyIsSubmitFormAllowed })
              }} />
              <p>{t('product_registration_form.form_labels_and_other_text.terms_and_conditions.condition3')} <span className='required'>*</span></p>
            </div>
          </div>
          {/* submit buttons */}
          {(isSubmitFormAllowed.condition1Checked && isSubmitFormAllowed.condition2Checked && isSubmitFormAllowed.condition3Checked) ? (
            <div>
              {isAdditionalWarrantySelected ? (
                <button style={{ backgroundColor: themeData.cssPrimaryColour, color: themeData.cssSecondaryColour }}
                  onClick={handelProceedTopayment}>
                  {t('additional_warranty_section.process_to_payment')}
                </button>
              ) : (
                <button style={{ backgroundColor: themeData.cssPrimaryColour, color: themeData.cssSecondaryColour }}
                  onClick={handelProductRegistrationSubmit}>
                  {t('product_registration_form.form_labels_and_other_text.submit_your_registration_form')}
                </button>
              )}
            </div>
          ) : (
            <div>
              {isAdditionalWarrantySelected ? (
                <button disabled style={{ backgroundColor: "", color: themeData.cssTextColour, cursor: "not-allowed" }}>{t('additional_warranty_section.process_to_payment')}</button>
              ) : (
                <button disabled style={{ backgroundColor: "", color: themeData.cssTextColour, cursor: "not-allowed" }}>{t('product_registration_form.form_labels_and_other_text.submit_your_registration_form')}</button>
              )}
            </div>
          )}
        </div>
      ) : (
        !isApiSubmitted ? (
          //  loading until api is submitted
          <div className="productRegistrationResponseLoading">
            <img src={pcg_logo} alt="pcg_logo" />
            <p> {t('thanks.product_registration_message')}</p>
            <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
              <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50" color={themeData.cssPrimaryColour} fill={themeData.cssPrimaryColour}>
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  dur="2s"
                  from="0 50 50"
                  to="360 50 50"
                  repeatCount="indefinite" />
              </path>
            </svg>
          </div>
        ) : (
          (isProceedToPaymentClicked) ? (
            // stripe payment section
            isPaymentIntentReceived ? (
              !isPaymentComplete ? (
                <div className='strip-payment'>
                  <Elements stripe={stripePromise} options={{ clientSecret: productRegistrationSubmitResponse.extendedWarranty.clientSecret }}>
                    <CheckoutForm themeData={themeData} handlePayClick={handlePayClick} />
                  </Elements>
                </div>
              ) :
                (!isPatchCallComplete ? (
                  //  loading until stripe payment intent is received
                  <div className="productRegistrationResponseLoading">
                    <img src={pcg_logo} alt="pcg_logo" />
                    <p> {t('thanks.payment_verification_message')}</p>
                    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                      <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50" color={themeData.cssPrimaryColour} fill={themeData.cssPrimaryColour}>
                        <animateTransform
                          attributeName="transform"
                          attributeType="XML"
                          type="rotate"
                          dur="2s"
                          from="0 50 50"
                          to="360 50 50"
                          repeatCount="indefinite" />
                      </path>
                    </svg>
                  </div>
                ) : (
                  <div className='productRegistrationResponse'>
                    {
                      status != null &&
                      <ThankYouPage themeData={themeData} isSuccess={status} productRegistrationSubmitResponse={productRegistrationSubmitResponse} isAdditionalWarrantySelected={isAdditionalWarrantySelected} isPaymentComplete={paymentInfo.status === 'succeeded'} />
                    }
                  </div>
                )
                )
            ) : (
              //  loading until stripe payment intent is received
              //  loading until api is submitted
              <div className="productRegistrationResponseLoading">
                <img src={pcg_logo} alt="pcg_logo" />
                <p> {t('thanks.product_registration_message')}</p>
                <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                  <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50" color={themeData.cssPrimaryColour} fill={themeData.cssPrimaryColour}>
                    <animateTransform
                      attributeName="transform"
                      attributeType="XML"
                      type="rotate"
                      dur="2s"
                      from="0 50 50"
                      to="360 50 50"
                      repeatCount="indefinite" />
                  </path>
                </svg>
              </div>
            )
          ) : (
            <div className='productRegistrationResponse'>
              {
                status != null &&
                <ThankYouPage themeData={themeData} isSuccess={status} productRegistrationSubmitResponse={productRegistrationSubmitResponse} isAdditionalWarrantySelected={isAdditionalWarrantySelected} isPaymentComplete={false} />
              }
            </div>
          )))}
    </div >
  );
};

export default ProductRegistrationForm;