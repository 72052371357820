import React from 'react';
import { useTranslation } from 'react-i18next';
import "../styles/thankYouPage.scss";
import { Link } from 'react-router-dom';

function ThankYouPage({ themeData, isSuccess, productRegistrationSubmitResponse, isAdditionalWarrantySelected, isPaymentComplete }) {
  const { t } = useTranslation();
  return (
    <div style={ { fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour } }>
      { isSuccess && (
        <div style={ { fontFamily: themeData.cssTextFontFamily } }>
          <h2 style={ { fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour, marginBottom: "15px", marginTop: "35px" } }>
            { t('thanks.thanks_for_register') }
          </h2>
          <div style={ { marginBottom: "5px" } }>
            { t('thanks.success_product') + " " } <b> { productRegistrationSubmitResponse.warranty.product.name } </b> { " " } <b> { productRegistrationSubmitResponse.warranty.product.sku } </b>
          </div>
          <div style={ { marginBottom: "5px" } }>
            { t('thanks.your_registration_number') + " " } <b>{productRegistrationSubmitResponse.warranty.id }</b> 
          </div>

          { !isAdditionalWarrantySelected ? (
            <div className='noadditionalwarrantysection'>
              <div >
                { t('thanks.your') + " " } <b> { productRegistrationSubmitResponse.warranty.product.name } </b> { " " } <b> { productRegistrationSubmitResponse.warranty.product.sku } </b>  { " " + t('thanks.coverage') + " " } <b> { productRegistrationSubmitResponse.warranty.expiryDate } </b> 
              </div>
              <div>
                { t('thanks.success_confirmation') + " " + productRegistrationSubmitResponse.emailAddress }
              </div>
            </div>
          ) : (
            isPaymentComplete ? (
              <div className='additionalwarrantysection'>
                <h2 style={ { fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour, marginBottom: "15px", marginTop: "35px" } }>
                  { t('thanks.extended_warranty_title') }
                </h2>
                <div>
                  { t('thanks.extended_warranty_line1_part1') + " " + productRegistrationSubmitResponse.warranty.extendedWarrantyPurchased.length / 12 + " " + t('thanks.extended_warranty_line1_part2') }
                </div>
                <div>
                  { t('thanks.extended_warranty_line2_part1') + " " } <b> { productRegistrationSubmitResponse.warranty.product.name } </b> { " " } <b> { productRegistrationSubmitResponse.warranty.product.sku } </b>  { " " + t('thanks.extended_warranty_line2_part2') + " "} <b> { productRegistrationSubmitResponse.warranty.expiryDate } </b> 
                </div>
                <h2 style={ { fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour, marginBottom: "15px", marginTop: "35px" } }>
                  { t('thanks.payment_title_part1') + " " + productRegistrationSubmitResponse.warranty.extendedWarrantyPurchased.amount + " " + t('thanks.payment_title_part2') }
                </h2>
                <div>
                  { t('thanks.payment_reference') + " " } <b> { productRegistrationSubmitResponse.warranty.extendedWarrantyPurchased.reference } </b>
                </div>
                <div>
                  { t('thanks.certificate') + " " + productRegistrationSubmitResponse.emailAddress }
                </div>
              </div>
            ) : (
              <div className='noadditionalwarrantysection'>
                <div>
                  { t('thanks.your') + " " } <b> { productRegistrationSubmitResponse.warranty.product.name } </b> { " " } <b> { productRegistrationSubmitResponse.warranty.product.sku } </b>  { " " + t('thanks.coverage') + " " } <b> { productRegistrationSubmitResponse.warranty.expiryDate } </b> 
                </div>
                <div>
                  { t('thanks.success_confirmation') + " " + productRegistrationSubmitResponse.emailAddress }
                </div>
                <h2 style={ { fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour, marginBottom: "15px", marginTop: "35px" } }>
                  Payment Failed
                </h2>
                <div>
                  { t('thanks.payment_reference') + " " } <b> { productRegistrationSubmitResponse.warranty.extendedWarrantyPurchased.reference } </b> 
                </div>
                <p style={ { marginTop: "10px", marginBottom: "0px" } }>
                  { t('thanks.problem_persists_support') }
                  <Link to={ "tel:" + themeData.csPhone }>
                    <a target='_blank'>{ themeData.csPhone }</a>
                  </Link>
                </p>
              </div>
            )
          ) }
          <div style={ { marginTop: "35px" } }>
            <button onClick={ (e) => {
              window.location.href = themeData.websiteURL
            } } style={ { backgroundColor: themeData.cssPrimaryColour, color: themeData.cssSecondaryColour } }>{ t('header.return_to_main_website_button') }</button>
            <button onClick={ (e) => {
              window.location.reload(true)
            } } style={ { backgroundColor: themeData.cssPrimaryColour, color: themeData.cssSecondaryColour } }>{ t('thanks.register_another_product') }</button>
          </div>
        </div>
      ) }
      { !isSuccess && (
        <div style={ { fontFamily: themeData.cssTextFontFamily } }>
          <p style={ { marginTop: "15px", fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour } } >
            { t('thanks.sorry_register_fail') }
          </p>
          <p style={ { marginBottom: "25px", marginTop: "10px" } }>
            { t('thanks.go_back_try_again') }
          </p>
          <p style={ { marginTop: "10px", marginBottom: "0px" } }>
            { t('thanks.problem_persists_support') }
            <Link to={ "tel:" + themeData.csPhone }>
              <a target='_blank'>{ themeData.csPhone }</a>
            </Link>
          </p>
          <div>
            <button onClick={ (e) => {
              window.location.reload(true)
            } } style={ { backgroundColor: themeData.cssPrimaryColour, color: themeData.cssSecondaryColour } }>{ t('thanks.back_to_registration_form') }</button>
          </div>
        </div>
      ) }
    </div>
  );
};

export default ThankYouPage;