import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import 'react-tooltip/dist/react-tooltip.css'

import App from './container/App';
import './i18n';

// 👇️ make sure to use the correct root element ID
// from your public/index.html file
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  // <StrictMode>
    <Suspense fallback="loading">
      <App />
    </Suspense>
  // </StrictMode>
);
