import React from 'react';
import { useTranslation } from 'react-i18next';
import "../styles/termsAndConditions.scss";

function TermsAndConditions({ themeData }) {
  const { t } = useTranslation();
  return (
    <div className='terms-and-conditions'>
      <h1 style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('terms_and_conditions.termsAndConditionsheader1')}</h1>
      <h2 style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('terms_and_conditions.termsAndConditionsheader2')}</h2>
      <p style={{ fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour }}>{t('terms_and_conditions.para1')}</p>
      <h2 style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('terms_and_conditions.termsAndConditionsheader3')}</h2>
      <p style={{ fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour }}>{t('terms_and_conditions.para2')}</p>
      <p style={{ fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour }}>{t('terms_and_conditions.para3')}</p>
      <ul style={{ fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour }}>
        <li>{t('terms_and_conditions.li1')}</li>
        <li>{t('terms_and_conditions.li2')}</li>
        <li>{t('terms_and_conditions.li3')}</li>
        <li>{t('terms_and_conditions.li4')}</li>
        <ul style={{ fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour }}>
          <li>{t('terms_and_conditions.li5')}</li>
          <li>{t('terms_and_conditions.li6')}</li>
          <li>{t('terms_and_conditions.li7')}</li>
        </ul>
        <li>{t('terms_and_conditions.li8')}</li>
        <li>{t('terms_and_conditions.li9')}</li>
      </ul>
      <h2 style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('terms_and_conditions.termsAndConditionsheader4')}</h2>
      <ul style={{ fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour }}>
        <li>{t('terms_and_conditions.li10')}</li>
        <li>{t('terms_and_conditions.li11')}</li>
        <li>{t('terms_and_conditions.li12')}</li>
        <li>{t('terms_and_conditions.li13')}</li>
        <li>{t('terms_and_conditions.li14')}</li>
      </ul>
      <h2 style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('terms_and_conditions.termsAndConditionsheader5')}</h2>
      <ul style={{ fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour }}>
        <li>{t('terms_and_conditions.li15')}</li>
        <ul style={{ fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour }}>
          <li>{t('terms_and_conditions.li16')}</li>
        </ul>
        <li>{t('terms_and_conditions.li17')}</li>
        <ul style={{ fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour }}>
          <li>{t('terms_and_conditions.li18')}</li>
        </ul>
      </ul>

      <h2 style={{ fontFamily: themeData.cssHeaderFontFamily, color: themeData.cssHeaderColour }}>{t('terms_and_conditions.termsAndConditionsheader6')}</h2>
      <p style={{ fontFamily: themeData.cssTextFontFamily, color: themeData.cssTextColour }}>{t('terms_and_conditions.para4')}</p>
    </div>
  );
};

export default TermsAndConditions;