import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";

export default function CheckoutForm({handlePayClick, themeData}) {
  const stripe = useStripe();
  const elements = useElements();

  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsProcessing(true);
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/404`,
      },
      redirect: "if_required"
    });

    if (error) {
      if(error.type){
        if(error.type == "validation_error"){
            setMessage(error.message);
        }
      }
      handlePayClick(error.payment_intent);
      setIsProcessing(false);
    }
    else if(paymentIntent && paymentIntent.status === "succeeded"){
      handlePayClick(paymentIntent);
      setIsProcessing(false);
    }
    else {
      setMessage("An unexpected error occurred.");
      handlePayClick(paymentIntent);
      setIsProcessing(false);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button  style={ { backgroundColor: themeData.cssPrimaryColour, color: themeData.cssSecondaryColour } } disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {/* {message && <div style={{color: "#990000"}} id="payment-message">{message}</div>} */}
    </form>
  );
}